import React from "react";
import Base from "../components/Base";
import setNavigation from "../helpers/SetNavigation";

export default function ContentVideos() {

	const navigation = setNavigation("Content Videos");

	return <Base navigation={navigation} crumb={"Content Videos"} />;
}
